
  import { Component, Vue } from "vue-property-decorator";
  import { formatDate, formatTime } from "@/date-utils";
  import { IProduct } from "@/interfaces";
  import { api } from "@/api";
  import { readToken } from "@/store/main/getters";
  import { dispatchAddNotification } from "@/store/main/actions";

  @Component
  export default class AdminProducts extends Vue {
    public headers = [
      {
        text: "Actions",
        value: "actions",
      },
      {
        text: "Name",
        value: "display_name",
        align: "left",
      },
      {
        text: "Price ($)",
        value: "price",
        align: "left",
      },
      {
        text: "Last Modified",
        value: "last_modified",
        align: "left",
      },
    ];

    loading = false;
    products: IProduct[] = [];
    selectedProduct: IProduct | null = null;
    dialog = false;
    price: number | null = null;

    async refreshData() {
      this.loading = true;
      const token = readToken(this.$store);

      const resp = await api.getProducts(token);
      this.products = resp.data;
      this.loading = false;
    }

    formatDate(date: string) {
      return formatDate(date, null);
    }

    formatTime(date: string) {
      return `${formatTime(date, null)}`;
    }

    public async mounted() {
      await this.refreshData();
    }

    editPrice(product: IProduct) {
      this.selectedProduct = product;
      this.price = product.price;
      this.dialog = true;
    }

    async updateProduct() {
      if (this.loading || !this.selectedProduct || !this.price) {
        return;
      }
      this.loading = true;
      const token = readToken(this.$store);
      const resp = await api.updateProduct(token, this.selectedProduct.id, {
        price: this.price,
      });
      this.selectedProduct = resp.data;
      this.dialog = false;
      dispatchAddNotification(this.$store, { content: "Price Updated" });
      this.refreshData();

      this.loading = false;
    }
  }
